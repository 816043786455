<template>
  <div class="login">
    <div class="container">
      <div class="logo"><img src="../../public/static/img/logo1.png"></div>
      <div class="title">
        用户名<br><input v-model="loginName" class="input" placeholder="请输入用户名">
      </div>
      <div class="title">
        密码<br><input v-model="password" type="password" class="input" placeholder="请输入密码">
      </div>
      <el-button type="primary" class="login-btn" @click="login()">登 录</el-button>
      <div class="findPassWord">忘记密码？ 请联系管理员！</div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginName: '',
      password: ''
    }
  },
  methods: {
    login() {
      if (!this.loginName) {
        this.$message.error('请输入用户名')
        return
      }

      if (!this.password) {
        this.$message.error('请输入密码')
        return
      }

      this.$http.post('/api/Auth/Login', { loginName: this.loginName, password: this.password }).then(res => {
        if (res.code === 1) {
          this.$message.success('登录成功')
          this.$store.commit('SET_TOKEN', '123456')
          this.$store.commit('SET_LOGIN_TIME', new Date().getTime())
          this.$router.push({ name: 'backStage' })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.login{
  background:#e1edf9;width:100%;height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;
  .logo{ text-align: center; padding-bottom:40px;}
  .container{
    width:545px;
    border-radius:20px;background:rgba(255,255,255,1);padding:30px 40px; box-sizing:border-box;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    .input{
      width: 440px;
      height: 54px;
      padding:0 13px;
      margin:10px 0 30px;
      color: #333;
      font-size: 14px;
      background: #f2f5fa;
      border-radius: 10px;
      font-family: '微软雅黑';
    }
    .login-btn{
      width: 466px;
      height: 54px;
      color: #f2f5fa;
      padding:0 13px;
      margin-top:20px;border-radius: 10px;
      font-family: '微软雅黑';
      font-size: 16px;
    }
    .findPassWord{ text-align: center; font-weight: normal; color: #888;padding:25px 0 10px;}
  }
}

</style>
